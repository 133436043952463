import "./Button.css";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const Button: React.FC<Props> = ({ className, onClick, children }) => {
  const classes = "button " + className;
  return (
    <button type="button" className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
