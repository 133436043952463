import Book from "./Book";
import classes from "./Books.module.css";
import split_img from "../../../assets/split.jpg";
import break_up_img from "../../../assets/break_up.jpg";
import managing_lit_img from "../../../assets/managing_litigation.jpg";
import from_creativity_img from "../../../assets/from_creativity.jpg";
import brevity_img from "../../../assets/brevity.jpg";
import counterparts_img from "../../../assets/counterparts.jpg";
import signatories_img from "../../../assets/signatories.jpg";
import tinyplays_img from "../../../assets/tiny_plays.jpg";
import Contribution from "./Contribution";
import React from "react";

const Books = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <section className={classes.section}>
      <h1 ref={ref}>Books</h1>
      <div>
        <Book
          img_src={split_img}
          book_href="https://www.amazon.com/Split-Stories-Relationship-Breakdown-Ireland/dp/1908023309/ref=sr_1_1?crid=2MS346CKFVL7Z&keywords=split+rachel+fehily&qid=1677421003&s=books&sprefix=split+rachel+fehily%2Cstripbooks-intl-ship%2C168&sr=1-1"
          book_title="Split: True Stories of Relationship Breakdown in Ireland"
        />
        <Book
          img_src={break_up_img}
          book_href="https://www.amazon.com/Break-Dont-Crack-Positive-Separation-ebook/dp/B076JJFJ8M/ref=sr_1_1?crid=3BL6YAIJ6JK7F&keywords=break+up+dont+crack+up+rachel+fehily&qid=1677420977&s=books&sprefix=break+up+dont+crack+up+rachel+fehily%2Cstripbooks-intl-ship%2C197&sr=1-1"
          book_title="Break up, Don't Crack up: A Positive Plan for Your Separation or Divorce in Ireland"
        />
        <Book
          img_src={managing_lit_img}
          book_href="https://www.overdrive.com/media/1308001/managing-litigation-for-your-business"
          book_title="Managing Litigation for Your Business"
        />
        <Book
          img_src={from_creativity_img}
          book_href="https://issuu.com/ucdoutreach/docs/ucdsc_mcguinness_exhibit_booklet"
          book_title="Frank McGuinness: From Creativity to Legacy"
        />
      </div>
      <h1>Contributions</h1>
      <Contribution
        image={brevity_img}
        sub_title="Co-Dependency (Short Story)"
        link="https://www.amazon.co.uk/Brevity-Soul-Wit-Lockdown-Ireland-ebook/dp/B08YNPVK4V"
        title="Brevity Is the Soul: Wit from Lockdown Ireland"
        description="When Ireland (along with much of the rest of the world) went into
        lockdown last spring, we scratched our heads and wondered what to do
        to help lift people's spirits. We joined forces with Irish Pensions &
        Finance and ran a competition celebrating Irish people's love of a
        good story – and a good laugh. There were only two requirements: make
        it funny, and make it (fairly) short. We were overwhelmed by the
        response: we received hundreds of entries, from all over the island
        (and further afield), written by people from all walks of life."
      />
      <Contribution
        image={counterparts_img}
        link="https://stingingfly.org/product/counterparts/"
        title="Counterparts"
        sub_title="Under Pressure (Play)"
        description="Counterparts is published in paperback and in two special limited editions. All profits are in aid of Peter McVerry Trust. The anthology is arranged as a series of pairings, with writing by contemporary Irish lawyer-writers paired with extracts from a legal judgment of the writers’ choosing. The writers also offer their reasons for choosing that particular judgment. In some cases a law report may have prompted a new piece of writing; in others, writers may see resonances of theme or language between the legal writing of the judgment and the themes they seek to explore in their own work; or it may simply be that they were drawn to a fascinating human story told in fine language, as the law reports so often deliver!"
      />
      <Contribution
        image={tinyplays_img}
        link="https://www.fishamble.com/store/p31/Tiny_Plays_for_Ireland_-_50_Tiny_Plays_by_various_writers.html"
        title="Tiny Plays for Ireland"
        sub_title="Don't Take It Personally (Short Play)"
        description="Fishamble, in partnership with the Irish Times, asked the Irish public what can be achieved with three minutes of stage time, what are the issues that need to be addressed, and who are the people that should be brought to life in the theatre?"
      />
      <Contribution
        image={signatories_img}
        link="https://www.ucdpress.ie/display.asp?isbn=9781910820100&#:~:text=Signatories%20comprises%20the%20artistic%20responses,signatories%20and%20Nurse%20O'Farrell."
        title="Signatories"
        sub_title="Thomas Clarke (Monologue)"
        description="2016 marks the centenary of the Easter Rising, known as 'the poets' rebellion', for among their leaders were university scholars of English, history and Irish. The ill-fated revolt lasted six days and ended ignominiously with the rebels rounded up and their leaders sentenced to death. The signatories of the Proclamation of the Irish Republic must have known that the Rising would be crushed, must have dreaded the carnage and death, must have foreseen that, if caught alive, they would themselves be executed. Between 3 and 12 May 1916, the seven signatories were among those executed by firing squad in Kilmainham Gaol. Now 100 years later, eight of Ireland's finest writers remember these revolutionaries in a unique theatre performance. The forgotten figure of Elizabeth O'Farrell - the nurse who delivered the rebels' surrender to the British - is also given a voice."
      />
    </section>
  );
});

export default Books;
