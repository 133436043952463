import rf_img from "../../assets/rf.jpg";
import classes from "./Intro.module.css";

const Intro = () => {
  return (
    <section className={classes["intro-section"]}>
      <img src={rf_img} alt="Rachel Fehily" />
      <div>
        <p>
          <i>
            <b>
              Rachel Fehily is an experienced barrister, university teacher, and
              versatile writer whose work has been widely published and
              performed. She teaches at University College Dublin in the School
              of English, Drama and Film and the School of Music.
            </b>
          </i>
        </p>
      </div>
    </section>
  );
};

export default Intro;
