import Card from "../../UI/Card";
import classes from "./RadioShow.module.css";

type Props = {
  title: string;
  bg_img: string;
  link: string;
};

const RadioShow: React.FC<Props> = ({ title, bg_img, link }) => {
  return (
    <a href={link} className="nostyle" target="_blank" rel="noreferrer">
      <Card
        className={classes["radio-show"]}
        backgroundImage={bg_img}
        imageDescription={title + " radio clip"}
      >
        <h2 className={classes.h2}>{title}</h2>
      </Card>
    </a>
  );
};

export default RadioShow;
