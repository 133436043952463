import classes from "./Opinion.module.css";
import OpinionPiece from "./OpinionPiece";
import irish_times_img from "../../../assets/it_logo.png";
import cao_img from "../../../assets/cao_choices.jpg";
import school_over_img from "../../../assets/school_is_over.jpg";
import ten_signs_img from "../../../assets/ten_signs.jpg";
import awaiting_decisions_img from "../../../assets/awaiting_decisions.jpg";
import marriage_img from "../../../assets/marriage.jpg";
import no_thanks_img from "../../../assets/no_thanks.jpg";
import pool_img from "../../../assets/pool.jpg";
import travel_abroad_img from "../../../assets/travel_abroad.jpg";
import React from "react";

const Opinion = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <section>
      <h1 ref={ref} className={classes.h1}>
        Articles
      </h1>
      <div className={classes.center}>
        <div className={classes.pieces}>
          <OpinionPiece
            title="No thanks for Carers who save the HSE €4bn a year. We have allowed yet another unpaid, exploited group to emerge in Irish Society"
            description="We have allowed yet another unpaid, exploited group to emerge in Irish society"
            image={no_thanks_img}
            journal="The Irish Times: Opinion"
            link="https://www.irishtimes.com/opinion/rachel-fehily-no-thanks-for-carers-who-save-hse-4bn-a-year-1.2892600"
          />
          <OpinionPiece
            title="Now we have marriage equality, how about equality in marriage?"
            image={marriage_img}
            description="In a married relationship economic power needs to be carefully balanced"
            journal="The Irish Times: Opinion"
            link="https://www.irishtimes.com/opinion/now-we-have-marriage-equality-how-about-equality-in-marriage-1.2279309"
          />
          <OpinionPiece
            title="Preparing for travel abroad when mobility is restricted"
            description="Prepare as much as you can in advance of your holiday so there isn’t a last-minute panic, and check out the following travel tips"
            image={travel_abroad_img}
            journal="The Irish Times: Health"
            link="https://www.irishtimes.com/life-and-style/health-family/preparing-for-travel-abroad-when-mobility-is-restricted-1.2759161"
          />
          <OpinionPiece
            title="Summer Snaps: A Pool in 1970s Ireland"
            description="The Clyde Road water feature was an attraction for our friends and cousins"
            image={pool_img}
            journal="The Irish Times: People"
            link="https://www.irishtimes.com/life-and-style/people/summer-snaps-a-pool-in-1970s-ireland-1.1890309"
          />
          <OpinionPiece
            title="Redress Could Have Been Far Easier For Abuse Victims"
            image={irish_times_img}
            description="Was an adversarial legal approach the best way to handle applications to the abuse redress board? I don’t think so..."
            journal="The Irish Times: Opinion"
            link="https://www.irishtimes.com/opinion/redress-could-have-been-far-easier-for-abuse-victims-1.648193"
          />
          <OpinionPiece
            title="I’m wistful for the random way we made CAO choices in the 80s"
            description="In the absence of psychometric tests or college open days, we took a more anarchic approach to life-defining career decisions. It seemed to work out well for most of us"
            image={cao_img}
            link="https://www.irishtimes.com/news/education/parent-s-diary-i-m-wistful-for-the-random-way-we-made-cao-choices-in-the-80s-1.3776130"
            journal="The Irish Times: Education"
            series="Leaving Cert Parent’s Diary"
          />
          <OpinionPiece
            title="The sight of my son playing Fortnite is enough to send me into a panic"
            description="10 tell-tale signs the State exams are looming all too soon in my house"
            image={ten_signs_img}
            link="https://www.irishtimes.com/news/education/leaving-cert-parents-diary-the-sight-of-my-son-playing-fortnite-is-enough-to-send-me-into-a-panic-1.3814339"
            journal="The Irish Times: Education"
            series="Leaving Cert Parent’s Diary"
          />
          <OpinionPiece
            title="School is over. Now it’s time to let go"
            description="We have to stand back and let them make their choices and their mistakes"
            image={school_over_img}
            link="https://www.irishtimes.com/news/education/leaving-cert-parent-s-diary-school-is-over-now-it-s-time-to-let-go-1.3914677"
            journal="The Irish Times: Education"
            series="Leaving Cert Parent’s Diary"
          />
          <OpinionPiece
            title="Limbo of waiting for Leaving Cert results is almost over"
            description="We are awaiting decisions about the future that are out of our hands"
            image={awaiting_decisions_img}
            link="https://www.irishtimes.com/news/education/limbo-of-waiting-for-leaving-cert-results-is-almost-over-1.3983117"
            journal="The Irish Times: Education"
            series="Leaving Cert Parent’s Diary"
          />
        </div>
        {/* <h2 className={classes.h2}>Series</h2>
        <Collapsible title="Leaving Cert Parent’s Diary">
          <OpinionSeries
            title="I’m wistful for the random way we made CAO choices in the 80s"
            description="In the absence of psychometric tests or college open days, we took a more anarchic approach to life-defining career decisions. It seemed to work out well for most of us"
            img={cao_img}
            link="https://www.irishtimes.com/news/education/parent-s-diary-i-m-wistful-for-the-random-way-we-made-cao-choices-in-the-80s-1.3776130"
          />
          <OpinionSeries
            title="The sight of my son playing Fortnite is enough to send me into a panic"
            description="10 tell-tale signs the State exams are looming all too soon in my house"
            img={ten_signs_img}
            link="https://www.irishtimes.com/news/education/leaving-cert-parents-diary-the-sight-of-my-son-playing-fortnite-is-enough-to-send-me-into-a-panic-1.3814339"
          />
          <OpinionSeries
            title="School is over. Now it’s time to let go"
            description="We have to stand back and let them make their choices and their mistakes"
            img={school_over_img}
            link="https://www.irishtimes.com/news/education/leaving-cert-parent-s-diary-school-is-over-now-it-s-time-to-let-go-1.3914677"
          />
          <OpinionSeries
            title="Limbo of waiting for Leaving Cert results is almost over"
            description="A Parent’s Diary: ‘We are awaiting decisions about the future that are out of our hands’"
            img={awaiting_decisions_img}
            link="https://www.irishtimes.com/news/education/limbo-of-waiting-for-leaving-cert-results-is-almost-over-1.3983117"
          />
        </Collapsible> */}
        <br />
      </div>
    </section>
  );
});

export default Opinion;
