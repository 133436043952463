import classes from "./Book.module.css";

type Props = {
  img_src: string;
  book_title: string;
  book_href: string;
};

const Book: React.FC<Props> = ({ img_src, book_title, book_href }) => {
  return (
    <div className={classes.book}>
      <a href={book_href} target="_blank" rel="noreferrer">
        <img src={img_src} alt={book_title} />
      </a>
    </div>
  );
};

export default Book;
