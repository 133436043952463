import { SocialIcon } from "react-social-icons";
import classes from "./Navigation.module.css";

const Navigation = () => {
  return (
    <nav className={classes.nav}>
      <ul>
        <li className={classes["hvr-icon-bounce"]}>
          <SocialIcon
            className={classes["hvr-icon"]}
            target="_blank"
            url="mailto:rfehily@hotmail.com"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li className={classes["hvr-icon-bounce"]}>
          <SocialIcon
            className={classes["hvr-icon"]}
            target="_blank"
            url="https://twitter.com/rfehily?lang=en"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li className={classes["hvr-icon-bounce"]}>
          <SocialIcon
            className={classes["hvr-icon"]}
            target="_blank"
            url="https://www.facebook.com/rachel.fehily/"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li className={classes["hvr-icon-bounce"]}>
          <SocialIcon
            className={classes["hvr-icon"]}
            target="_blank"
            url="https://www.linkedin.com/in/rachel-fehily-9b8b4618/"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
      </ul>

      {/* <ul>
        <li>
          <SocialIcon
            target="_blank"
            url="mailto:rfehily@hotmail.com"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li>
          <SocialIcon
            target="_blank"
            url="https://twitter.com/rfehily?lang=en"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li>
          <SocialIcon
            target="_blank"
            url="https://www.facebook.com/rachel.fehily/"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
        <li>
          <SocialIcon
            target="_blank"
            url="https://www.linkedin.com/in/rachel-fehily-9b8b4618/"
            style={{ height: 40, width: 40 }}
            fgColor="#ffffff"
          />
        </li>
      </ul> */}
    </nav>
  );
};

export default Navigation;
