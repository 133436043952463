import Button from "../UI/Button";
import classes from "./ButtonRow.module.css";

type Props = {
  onClickBooks: () => void;
  onClickRadio: () => void;
  onClickArticles: () => void;
  onClickAbout: () => void;
};

const ButtonRow: React.FC<Props> = ({
  onClickBooks,
  onClickRadio,
  onClickArticles,
  onClickAbout,
}) => {
  return (
    <div className={classes.container}>
      <div />
      <Button onClick={onClickBooks}>Books</Button>
      <div />
      <Button>Contributions</Button>
      <div />
      <Button onClick={onClickRadio}>Radio</Button>
      <div />
      <Button onClick={onClickArticles}>Articles</Button>
      <div />
      <Button onClick={onClickAbout}>Bio</Button>
      <div />
    </div>
  );
};

export default ButtonRow;
