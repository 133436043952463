import { useRef } from "react";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import Intro from "./components/Layout/Intro";
import About from "./components/sections/About/About";
import Books from "./components/sections/Books/Books";
import Opinion from "./components/sections/Opinion/Opinion";
import Radio from "./components/sections/Radio/Radio";

const App = () => {
  const booksRef = useRef<HTMLDivElement>(null);
  const radioRef = useRef<HTMLDivElement>(null);
  const articlesRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  const handleClickBooks = () => {
    booksRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickRadio = () => {
    radioRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickArticles = () => {
    articlesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClickAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header
        onClickBooks={handleClickBooks}
        onClickArticles={handleClickArticles}
        onClickRadio={handleClickRadio}
        onClickAbout={handleClickAbout}
      />
      <Intro />
      <Books ref={booksRef} />
      <Radio ref={radioRef} />
      <Opinion ref={articlesRef} />
      <About ref={aboutRef} />
      <Footer />
    </>
  );
};

export default App;
