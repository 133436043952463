import React from "react";
import classes from "./About.module.css";

const About = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <section className={classes.section}>
      <h1 ref={ref}>Bio</h1>
      <div>
        <p>
          <i>
            <b>Rachel Fehily</b> is an experienced barrister, university teacher
            and versatile writer whose work has been widely published and
            performed. She teaches at University College Dublin in the School of
            English, Drama and Film and the School of Music.
          </i>
        </p>
      </div>
    </section>
  );
});

export default About;
