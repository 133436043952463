import React from "react";
import classes from "./Card.module.css";

type Props = {
  children?: React.ReactNode;
  className?: string;
  backgroundImage?: string;
  imageDescription?: string;
};

const Card: React.FC<Props> = ({
  children,
  className,
  backgroundImage,
  imageDescription,
}) => {
  return (
    <div className={`${classes.card} ${className}`}>
      {backgroundImage && <img src={backgroundImage} alt={imageDescription} />}
      <section>{children}</section>
    </div>
  );
};

export default Card;
