import classes from "./OpinionPiece.module.css";

type Props = {
  title?: string;
  image?: string;
  image_alt?: string;
  link?: string;
  journal?: string;
  description?: string;
  series?: string;
};
const OpinionPiece: React.FC<Props> = ({
  title,
  image,
  link,
  journal,
  description,
  series,
}) => {
  return (
    <a href={link} className="nostyle" target="_blank" rel="noreferrer">
      <section className={classes.section}>
        <div className={classes["text-div"]}>
          <h5>{journal}</h5>
          {/* <h4>{series}</h4> */}
          <h3>{title}</h3>
          <p>{description}</p>
        </div>

        <img src={image} alt={title} className={classes.img} />
      </section>
    </a>
  );
};

export default OpinionPiece;
