import ButtonRow from "./ButtonRow";
import classes from "./Header.module.css";
import TopHeaderRow from "./TopHeaderRow";

type Props = {
  onClickBooks: () => void;
  onClickRadio: () => void;
  onClickArticles: () => void;
  onClickAbout: () => void;
};

const Header: React.FC<Props> = ({
  onClickBooks,
  onClickRadio,
  onClickArticles,
  onClickAbout,
}) => {
  return (
    <header className={classes.header}>
      <TopHeaderRow />
      <ButtonRow
        onClickBooks={onClickBooks}
        onClickRadio={onClickRadio}
        onClickArticles={onClickArticles}
        onClickAbout={onClickAbout}
      />
    </header>
  );
};
export default Header;
