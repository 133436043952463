import { useState } from "react";
import classes from "./Contribution.module.css";

type Props = {
  image: string;
  title: string;
  description: string;
  sub_title: string;
  link: string;
};

const Contribution: React.FC<Props> = ({
  image,
  title,
  sub_title,
  description,
  link,
}) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <a href={link} className="nostyle" target="_blank" rel="noreferrer">
      <section className={classes.contribution}>
        <img src={image} alt={title} />
        <div>
          <h2>{title}</h2>
          <h3>{sub_title}</h3>

          <p>
            {description.length < 250
              ? description
              : showMore
              ? description
              : `${description.match(/^.{250}\w*/)}`}
            {/* space separator */}
            &#160;
            <span
              onClick={(event) => {
                event.preventDefault();
                setShowMore((prev) => !prev);
              }}
            >
              {description.length < 250
                ? null
                : showMore
                ? "see less"
                : "\nsee more"}
            </span>
          </p>
        </div>
      </section>
    </a>
  );
};
export default Contribution;
