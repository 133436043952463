import classes from "./Radio.module.css";
import RadioShow from "./RadioShow";
import { Carousel } from "@trendyol-js/react-carousel";
import sunday_img from "../../../assets/sunday_misc.jpg";
import rte_img from "../../../assets/rte_radio.jpg";
import tippFM_img from "../../../assets/tipp_fm.jpg";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useLayoutEffect, useState } from "react";
import React from "react";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const Radio = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [width] = useWindowSize();

  const calculateShowNumber = (width: number) => {
    // if (width < 410) {
    //   return 1;
    // } else
    if (width < 480) {
      return 1.5;
    } else if (width < 768) {
      return 3;
    } else if (width < 1024) {
      return 6;
    } else {
      return 7;
    }
  };

  return (
    <section>
      <h1 ref={ref} className={classes.h1}>
        Radio
      </h1>
      <Carousel
        leftArrow={
          <div style={{ height: "100%", paddingTop: "110%" }}>
            <ChevronLeft color="#000000" size={60} />
          </div>
        }
        rightArrow={
          <div style={{ height: "100%", paddingTop: "110%" }}>
            <ChevronRight color="#000000" size={60} />
          </div>
        }
        show={calculateShowNumber(width)}
        slide={1}
        swiping={true}
      >
        <RadioShow
          title="Pelican House"
          bg_img={sunday_img}
          link="https://www.rte.ie/radio/radio1/clips/21243427/"
        />
        <RadioShow
          title="Grieving Time"
          bg_img={rte_img}
          link="https://www.rte.ie/radio/dramaonone/1241306-grieving-time-by-rachel-fehily"
        />
        <RadioShow
          title="Ecstatic Dancing"
          bg_img={sunday_img}
          link="https://www.rte.ie/radio/radio1/clips/21181362/"
        />
        <RadioShow
          title="I am Irish"
          bg_img={sunday_img}
          link="https://www.rte.ie/radio/radio1/clips/21145017/"
        />
        <RadioShow
          title="Tipp FM Advice Slot"
          bg_img={tippFM_img}
          link="https://www.youtube.com/watch?v=ZACkHuqFVfs"
        />
        <RadioShow
          title="Love of My Life"
          bg_img={sunday_img}
          link="https://www.rte.ie/radio/radio1/clips/21138082/"
        />
        <RadioShow
          title="Your Relationship in a Pandemic"
          bg_img={rte_img}
          link="https://www.rte.ie/radio/radio1/clips/21910741/"
        />
        <RadioShow
          title="Chapels on San Giorgio di Maggiore"
          bg_img={sunday_img}
          link="https://www.rte.ie/radio/radio1/clips/21402833/"
        />
      </Carousel>
    </section>
  );
});

export default Radio;
