import Navigation from "./Navigation";
import classes from "./TopHeaderRow.module.css";

const TopHeaderRow = () => {
  return (
    <div className={classes["top-row"]}>
      <h1>
        <a className={classes.nostyle} href="/">
          Rachel Fehily
        </a>
      </h1>
      <Navigation />
    </div>
  );
};

export default TopHeaderRow;
